<template>
  <div v-if="isMobile" class="phoneDetailsBox">
    <div class="banner">
      <img :src="constUrl + detail.picture"/>
    </div>
    <div class="detailsBox">
      <el-page-header @back="goBack" content="新闻中心">
      </el-page-header>
      <div class="detailsContent">
        <p class="detailsContentTitle">{{detail.name}}</p>
        <div class="timeBox">
          <span>时间：{{detail.createTime|formatTimer()}}</span>
          <span>浏览次数：{{detail.count}}次</span>
        </div>
        <el-divider></el-divider>
        <div v-if="detail.richText" class="detail_html richText ql-editor" v-html="detail.richText" @click="clickDom($event)"></div>
        <div v-else class="no_detail">
          内容补充中...
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="banner">
      <img :src="constUrl + detail.picture"/>
    </div>
    <div class="detailsBox">
      <el-page-header @back="goBack" content="新闻中心">
      </el-page-header>
      <div class="detailsContent">
        <h2 class="title">{{detail.name}}</h2>
        <div class="timeBox">
          <span>时间：{{detail.createTime|formatTimer()}}</span>
          <span>浏览次数：{{detail.count}}次</span>
        </div>
        <el-divider></el-divider>
        <div v-if="detail.richText" class="detail_html richText ql-editor" v-html="detail.richText" @click="clickDom($event)"></div>
        <div v-else class="no_detail">
          内容补充中...
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Format from '@/utils/format'
import ItemMixins from "../item.mixins";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  mixins: [ItemMixins],
  data() {
    return {
      detail: {},
    }
  },
  watch: {
    // 监听路由变化，路由变化的时候请求页面数据
    '$route': function () {
      this.init()
    }
  },
  filters: {
    // 时间格式化
    formatTimer(timer) {
      return Format(new Date(timer))
    },
  },
  methods: {
    async init() {
      let id = this.$route.params.id
      this.detail = await this.getDetailById(id)
      this.detail.richText = this.detail.richText.replace(/\<img src="/gi, '<img src="'+this.constUrl)
      this.$emit('topPic', this.detail.picture)
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="less" scoped>
  .casesPhoneBox{
    .banner{
      height: 100%;
      background: mediumspringgreen;
    }
  }
</style>

